import React from 'react';
import qs from 'qs';

import useStripe from 'src/hooks/stripe';
import CancelButton from 'src/components/design-system/buttons/cancel';
import Submit from 'src/components/design-system/buttons/submit';
import FormFooter from 'src/components/design-system/form/footer';
import Card from 'src/components/design-system/card';
import { SecondaryTable } from 'src/components/design-system/table';
import P from 'src/components/design-system/p';
import H3 from 'src/components/design-system/h3';

const Recap = ({ prebookId, data }) => {
  const { goToCheckout, isLoading } = useStripe({ prebookId });

  return (
    <>
      <Card className="mt-4">
        <div>
          <h3 className="text-lg font-medium text-gray-900 leading-6">
            Coordonnées et enfant(s)
          </h3>
        </div>
        <div className="pt-5 mt-5 border-t border-gray-200">
          <dl>
            <div className=" sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500 leading-5">
                Parent 1
              </dt>
              <dd className="mt-1 text-sm text-gray-900 leading-5 sm:mt-0 sm:col-span-2">
                {data.form.parent1_prenom} {data.form.parent1_nom}
              </dd>
            </div>

            {data.form.parent2_prenom && data.form.parent2_nom && (
              <div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-gray-200 sm:pt-5">
                <dt className="text-sm font-medium text-gray-500 leading-5">
                  Parent 2
                </dt>
                <dd className="mt-1 text-sm text-gray-900 leading-5 sm:mt-0 sm:col-span-2">
                  {data.form.parent2_prenom} {data.form.parent2_nom}
                </dd>
              </div>
            )}

            <div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-gray-200 sm:pt-5">
              <dt className="text-sm font-medium text-gray-500 leading-5">
                E-mail principal
                <div className="text-xs text-red-400">
                  Cet e-mail vous servira d'identifiant
                </div>
              </dt>
              <dd className="mt-1 text-sm text-gray-900 leading-5 sm:mt-0 sm:col-span-2">
                {data.form.mail_principal}
              </dd>
            </div>

            {data.form.mail_secondaire && (
              <div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-gray-200 sm:pt-5">
                <dt className="text-sm font-medium text-gray-500 leading-5">
                  E-mail secondaire
                </dt>
                <dd className="mt-1 text-sm text-gray-900 leading-5 sm:mt-0 sm:col-span-2">
                  {data.form.mail_secondaire}
                </dd>
              </div>
            )}

            <div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4  sm:border-gray-200 sm:pt-5">
              <dt className="text-sm font-medium text-gray-500 leading-5">
                Téléphone(s)
              </dt>
              <dd className="mt-1 text-sm text-gray-900 leading-5 sm:mt-0 sm:col-span-2">
                {data.form.tel_principal}
                {data.form.tel_secondaire}
              </dd>
            </div>

            <div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-gray-200 sm:pt-5">
              <dt className="text-sm font-medium text-gray-500 leading-5">
                Adresse
              </dt>
              <dd className="mt-1 text-sm text-gray-900 leading-5 sm:mt-0 sm:col-span-2">
                {data.form.adresse} <br />
                {data.form.code_postal} {data.form.ville}
              </dd>
            </div>
            <div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4  sm:border-gray-200 sm:pt-5">
              <dt className="text-sm font-medium text-gray-500 leading-5">
                Enfant(s)
              </dt>
              <dd className="mt-1 text-sm text-gray-900 leading-5 sm:mt-0 sm:col-span-2">
                <SecondaryTable
                  className=""
                  lines={data.form.enfants.map((e) => [
                    `${e.prenom} ${e.nom}`,
                    <div className="text-right">{e.date_naissance}</div>,
                  ])}
                />
              </dd>
            </div>
          </dl>
        </div>
      </Card>

      <Card className="mt-8">
        <div>
          <h3 className="text-lg font-medium text-gray-900 leading-6">Tarif</h3>
        </div>

        <div className="mt-8 sm:mt-5 sm:border-t sm:border-gray-200 sm:pt-5">
          {data.form.reduc && (
            <P className="text-sm mb-4">
              Ce montant tient compte d'une réduction pour condition de
              ressources ou en cas d'enfant(s) en situation de handicap.{' '}
              <strong>Un justificatif devra être fourni.</strong>
            </P>
          )}
          <SecondaryTable
            compact={false}
            lines={data.invoice.lines.map((l) => [l.label, l.price])}
            footers={['Total', data.invoice.total]}
          />
        </div>
        <div className="mt-4">
          <H3>Note concernant les créneaux</H3>
          <P>
            L'attribution par la Mairie de Paris des créneaux horaires des
            équipements aux associations n'est pas définitive au moment où nous
            ouvrons les inscriptions. En conséquence, l'association se réserve
            la possibilité de vous proposer un autre créneau ou de vous
            rembourser dans le cas où les créneaux choisis ne seraient pas
            disponibles à la rentrée.
          </P>
        </div>
      </Card>

      <FormFooter>
        <CancelButton
          to={`/inscription/infos?${qs.stringify({ id: prebookId })}`}
        >
          Editer votre demande
        </CancelButton>
        <Submit type="submit" onClick={goToCheckout} isLoading={isLoading}>
          Payer
        </Submit>
      </FormFooter>
    </>
  );
};

export default Recap;
