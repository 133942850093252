import useAxios from './axios/useAxios';
import { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import routes from 'src/config/routes';

const { GATSBY_STRIPE_KEY } = process.env;

const stripePromise = loadStripe(GATSBY_STRIPE_KEY);

const useStripe = function({ prebookId }) {
  const [isLoading, setIsLoading] = useState(false);
  const { load: getSessionId } = useAxios({
    method: 'post',
    url: routes.makeStripeSession(prebookId),
  });

  const goToCheckout = async () => {
    try {
      setIsLoading(true);
      const {
        data: {
          data: { session_id: sessionId },
        },
      } = await getSessionId();

      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({
        sessionId,
      });

      console.log(error);
    } catch (e) {
      console.log(e);
    }

    setIsLoading(false);
  };

  return { isLoading, goToCheckout };
};

export default useStripe;
