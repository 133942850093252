import React, { useEffect } from 'react';
import qs from 'qs';
import Recap from 'src/components/inscription/recap';
import ContentLayout from 'src/components/layouts/content';
import { H2 } from 'src/components/layouts/content/theme';
import SEO from 'src/components/seo';

import routes from 'src/config/routes';

import useAxios from 'src/hooks/axios/useAxios';

import Loading from 'src/components/design-system/loading';
import { navigate } from 'gatsby';

const RecapPage = ({ location }) => {
  const { id } = location.search ? qs.parse(location.search.slice(1)) : {};
  const { requestState, load } = useAxios({
    url: routes.prebook(id),
  });

  useEffect(() => {
    if (!id) navigate('/');
    load();
  }, [id]);

  return (
    <ContentLayout>
      <SEO title="Récapitulatif de l'inscription" />
      <H2>Inscription – étape 2 / 4</H2>
      <Loading requestState={requestState}>
        {(rs) => {
          const { data } = rs;
          return <Recap data={data.data} prebookId={id} />;
        }}
      </Loading>
    </ContentLayout>
  );
};

export default RecapPage;
